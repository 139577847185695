import React, { useState, useEffect } from 'react';
import AdminLayout from '../../components/admin/AdminLayout'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormGroup, FormControlLabel, Switch } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import {
  getOrgList,
  callOrg
} from '../../services/Org'

export default function OrgList() {
  const [org_list, setOrgList] = useState([])
  const [selected_org_id, setSelectedOrgID] = useState(0)
  const [alert_header, setAlertHeader] = useState("")
  const [alert_body, setAlertBody] = useState("")
  const [alert_mode, setAlertMode] = useState("")
  const [open_alert, setOpenAlert] = useState(false);
  const [selected_status,setSelectedStatus] = useState()

  const handleClose = () => {
    setOpenAlert(false);
    setSelectedOrgID(0)
  };

  const columns = [
    { 
      field: 'index', 
      headerName: 'ลำดับ', 
      maxWidth: 50,
      flex:1
    },
    { 
      field: 'org_code', 
      headerName: 'รหัสหน่วยงาน', 
      minWidth: 250,
      flex:1
    },
    { 
      field: 'org_name', 
      headerName: 'ชื่อหน่วยงาน', 
      minWidth: 250,
      flex:1
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      maxWidth: 100,
      flex:1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return <FormGroup>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={cellValues.row.status == 'A'}
                onClick={(event) => {
                  handleStatusSwitch(event, cellValues);
                }}
              />
            }
            label=""
          />
        </FormGroup>
      }
    },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      maxWidth: 100,
      flex:1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return <IconButton
          color="secondary"
          aria-label="add an alarm"
          onClick={(event) => {
            handleEditClick(event, cellValues);
          }} >
          <EditIcon />
        </IconButton>
      }
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      maxWidth: 100,
      flex:1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return <IconButton
          color="secondary"
          aria-label="add an alarm"
          onClick={(event) => {
            handleDeleteClick(event, cellValues);
          }} >
          <DeleteIcon />
        </IconButton>
      }
    }
  ];

  const handleStatusSwitch = (event, cellValues) => {
    let org_id = cellValues.row.id
    let org_name = cellValues.row.org_name
    let status = cellValues.row.status === 'A'
    let header = " User"
    let body = "คุณต้องการ "
    header = status ? 'ปิดการใช้งาน' + header : 'เปิดการใช้งาน' + header
    body = status ? body + ' ปิดการใช้งาน ' : body + ' เปิดการใช้งาน '
    body += "หน่วยงาน " + org_name
    setAlertHeader(header)
    setAlertBody(body)
    setSelectedOrgID(org_id)
    setSelectedStatus(status)
    setAlertMode("status")
    setOpenAlert(true)
  }


  const handleEditClick = (event, cellValues) => {
    event.preventDefault();
    window.location.href = "/org/edit/" + cellValues.row.id
  }

  const handleDeleteClick = (event, cellValues) => {
    event.preventDefault();
    let org_id = cellValues.row.id
    let org_name = cellValues.row.org_name
    let header = "ลบข้อมูลหน่วยงาน"
    let body = "คุณต้องการลบช้อมูล "
    body += "หน่วยงาน " + org_name
    setAlertHeader(header)
    setAlertBody(body)
    setSelectedOrgID(org_id)
    setAlertMode("delete")
    setOpenAlert(true)
  }

  const createOrgList = () => {
    getOrgList().then(res => {
      let all_org = res.data.data
      console.log(all_org);
      let temp_org_list = []
      all_org.map((item, index) => {
        temp_org_list.push({
          id: item.org_id,
          index: index + 1,
          org_name: item.org_name,
          org_code: item.org_code,
          status: item.org_status,
        })
      })
      setOrgList(temp_org_list)
    }).catch(error => {
      console.log(error.response);
      if (error.response.status === 401) {
        window.location.href = "/auth/login";
      }
    })
  }

  const handleAddClick = (event) => {
    event.preventDefault();
    window.location.href = "/org/add";
  }

  const handleAlertButton = () => {
    if(alert_mode === "delete"){
    let obj = {
      org_id: selected_org_id,
    }
    console.log(obj);
    callOrg("organizations/delete", obj).then(res => {
      handleClose()
      setOrgList([])
      createOrgList()
    }).catch(error => {
      console.log(error.response);
      if (error.response) {
        if (error.response.status === 401) {
          window.location.href = "/auth/login";
        }
      }
    })
  }else{
    let status = selected_status ? "inactive" : "active"
    let obj = {
      "org_id" : selected_org_id
    }
    callOrg('organizations/' + status ,obj).then(res => {
      handleClose()
      setOrgList([])
      createOrgList()
    }).catch(error=>{
      if(error.response){
        if(error.response.status === 401){
          window.location.href = "/auth/login";
        }
      }
    })
  }
  }

  useEffect(() => {
    createOrgList()
  }, [])

  return (
    <div style={{ margin: '2rem' }}>
      <AdminLayout />
      <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
        <Grid item xs>
          <Typography variant='h4' style={{ fontWeight: 'bold' }}>Organization Management</Typography>
        </Grid>
        <Grid item >
          <Button
            variant="contained"
            onClick={handleAddClick}
          >
            ADD ORG
          </Button>
        </Grid>
        <Grid item xs={12} >
          <div style={{ height: 600, width: '100%', marginTop: '2rem' }}>
            <DataGrid
              style={{ backgroundColor: "#FFFFFF" }}
              rows={org_list}
              columns={columns}
              rowsPerPageOptions={[]}
            />
          </div>
        </Grid>
      </Grid>
      <Dialog
        open={open_alert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {alert_header}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alert_body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertButton} variant="contained" color="success">
            ตกลง
          </Button>
          <Button onClick={handleClose} variant="contained" color="error">
            ยกเลิก
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}