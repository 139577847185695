/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
// import Grid from '@mui/material/Grid';
// import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// import Select from '@mui/material/Select';
// import  MenuItem from '@mui/material/MenuItem';
import { 
  Card,
  Grid,
  CardActions,
  CardContent,
  TextField,
  Button,
  Select,
  MenuItem
} from '@mui/material'

import {
  useLocation
} from 'react-router-dom';

import {
  addMenu,
  updateMenu,
  menuDetail
} from '../services/admin/Menu'

import {
  callService,
} from '../services/Service'

const MenuForm = ({ mode }) => {
  const location = useLocation();
  const [button_title, setButtonTitle] = useState("")
  const [selected_application,setSelectedApplication] = useState(0)
  const [selected_parent_menu,setSelectedParentMenu] = useState(0)
  const [selected_menu_type,setSelectedMenuType] = useState("")
  const [main_menu_list,setMainMenuList] = useState([])
  const [application_list,setApplicationList]= useState([])
  const [data, setData] = useState({
    org_name: "",
    org_code: "",
  })

  const [isBusy, setBusy] = useState(mode === "add" ? false : true)

  // const [isBusy,setBusy] = useState('')


const handleBackButton = () => window.location.href = "/menu/list";;

  useEffect(() => {
    createApplicationDropdown()
    if (mode === "edit") {
      setButtonTitle("แก้ไขเมนู")
      let app_id = location.pathname.split('/')[3]
      let menu_id = location.pathname.split('/')[4]
      let object = {
        "app_id" : app_id,
        "menu_id" : menu_id
      }
      menuDetail(object)
        .then(res => {
          let detail = res.data.data[0]
          console.log(res.data.data[0]);
          setData(res.data.data[0])
          setSelectedApplication(detail.app_id)
          setSelectedMenuType(detail.menu_type);
          handleSelectedApplication(detail.app_id);
          setSelectedParentMenu(detail.parent_menu_id);
          setBusy(false)
        })
    } else {
      setButtonTitle("เพิ่มเมนู")
    }

  }, []); // Or [] if effect doesn't need props or state


  const createApplicationDropdown = () => {
    callService('applications').then(
      res => {
        let app_list = [];
        let data = res.data.data;
        data.map((item) => {
          app_list.push({
            "id" : item.app_id,
            "name" : item.app_name
          })
        })
        setApplicationList(app_list);
        console.log(app_list);
      }).catch(error =>{
        console.log(error.response.data.response);
      })
  }

  const handleSelectedApplication = (value) =>{
    if(mode !== "edit"){
      setSelectedMenuType("");
      setSelectedParentMenu(0);
    }
    if(value !== 0){
      let object = {
        "app_id" : value,
        "menu_type" : "main"
      }
      callService('applications/menu/get/type',object)
      .then(res => {
        let main_list = [];
        let data = res.data.data
        data.map((item) => {
          main_list.push({
            "id" : item.menu_id,
            "name" : item.menu_name
          })
        })
        setMainMenuList(main_list);
      }).catch( error => {
        console.log(error.response.data.response);
      })
    }else{
      setMainMenuList([])
    }
    return setSelectedApplication(value);
  }

  const handleSaveButton = () => {
    if (data.selected_application === 0) {
      alert("คุณยังไม่ระบุแอพพลิเคชั่น \n กรุณาเลือกแอพพลิเคชั่น")
    } else if (data.menu_name === "" ) {
      alert("คุณยังไม่ระบุชื่อเมนู \n กรุณาเพิ่มชื่อเมนู")
    } else if(selected_menu_type  === 0){
      alert("คุณยังไม่ได้ระบุประเภทเมนู \n กรุณาเลือกประเภท")
    }else if(selected_menu_type === "sub" && selected_parent_menu === 0 ){
      alert("คุณยังไม่ได้ระบุเมนูหลัก \n คุณต้องเลือกเมนูหลัก")
    }
     else {
      if (mode === "add") {
        let object = {
          "app_id": selected_application,
          "menu_name": data.menu_name,
          "menu_type" : selected_menu_type,
          "parent_menu_id" : selected_menu_type === "main" ? 0 : selected_parent_menu,
          "menu_uri" : data.menu_uri
          //"meu_logo": data.org_logo,
        }
        console.log(object)
        addMenu(object)
          .then(res => {
            alert("เพิ่มเมนูสำเร็จ")
            window.location.href = "/menu/list";
          })
          .catch(error => {
            console.log(error.response);
            if (error.response) {
              if (error.response.status === 401) {
                window.location.href = "/auth/login";
              }
            }
          })
      } else {
        let app_id = location.pathname.split('/')[3]
        let menu_id = location.pathname.split('/')[4]
        let object = {
          "app_id": app_id,
          "menu_id" : menu_id,
          "menu_name": data.menu_name,
          "menu_type" : selected_menu_type,
          "parent_menu_id" : selected_menu_type === "main" ? 0 : selected_parent_menu,
          "menu_uri" : data.menu_uri
        }
        updateMenu(object)
          .then(res => {
            alert("แก้ไขเมนูสำเร็จ")
            window.location.href = "/menu/list";
          })
          .catch(error => {
            console.log(error.response);
            if (error.response) {
              if (error.response.status === 401) {
                window.location.href = "/auth/login";
              }
            }
          })
      }
    }
  }

  if (isBusy) {
    return <div>Loading...</div>;
  } else {
    return (
      <Card style={{ marginTop: '1.5rem', marginRight: '10%', marginLeft: '10%' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
            <Select
            labelid="select-app_id-label"
            id="select-app_id"
            value={selected_application}
            label="แอพพลิเคชั่น"
            disabled = {mode==="edit"? true : false}
            fullWidth
            onChange={event => handleSelectedApplication(event.target.value)}
              >
                <MenuItem key={0} value={0}><em>กรุณาเลือกแอพพลิเคชั่น</em></MenuItem>
                {application_list.map((item,index) => {
                  return (<MenuItem key={index+1} value={item.id}>{item.name}</MenuItem>)
                })}
            </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-menu_name"
                label="ชื่อเมนู"
                variant="outlined"
                value={data.menu_name}
                onChange={event => setData({ ...data, 'menu_name': event.target.value })}
              />
            </Grid>
            <Grid item xs={6}>
            <Select
              labelid="select-menu_type-label"
              id="select-menu_type"
              value={selected_menu_type}
              displayEmpty
              label="ประเภทเมนู"
              fullWidth
              onChange={event => setSelectedMenuType(event.target.value)}
            >
              <MenuItem value=""><em>กรุณาเลือกเมนูหลัก</em></MenuItem>
              <MenuItem value="main">Main</MenuItem>
              <MenuItem value="sub">Sub</MenuItem>
            </Select>
            </Grid>
            <Grid item xs={6}>
            <TextField
                fullWidth
                id="outlined-menu_uri"
                label="เมนู Uri"
                variant="outlined"
                value={data.menu_uri}
                onChange={event => setData({ ...data, 'menu_uri': event.target.value })}
              />
            </Grid>
            { selected_menu_type === "sub" &&
            (
            <Grid item xs={6}>
            <Select
            labelid="select-parent_menu-label"
            id="select-parent_menu"
            value={selected_parent_menu}
            label="ประเภทเมนู"
            fullWidth
            onChange={event => setSelectedParentMenu(event.target.value)}
              >
                <MenuItem key={0} value={0}>กรุณาเลือกเมนูหลัก</MenuItem>
                {main_menu_list.map((item,index) => {
                  return (<MenuItem key={index+1} value={item.id}>{item.name}</MenuItem>)
                })}
            </Select>
              </Grid>)
            }
            
          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              <Button
                variant="contained"
                color="success"
                onClick={handleSaveButton}
              >
                {button_title}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="error"
                onClick={handleBackButton}
              >
                ย้อนกลับ
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    )
  }

}
export default MenuForm;