import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import {
  useLocation
} from 'react-router-dom';

import {
  callService,
  userDetail
} from '../services/Service'
import { ConstructionOutlined } from '@mui/icons-material';


const UserForm = ({ mode }) => {
  const location = useLocation();
  const [button_title, setButtonTitle] = useState("")
  const [menu_list,setMenuList] = useState([])
  const [data, setData] = useState('')
  const [isBusy, setBusy] = useState(mode === "add" ? false : true)

  useEffect(() => {
    if (mode === "edit") {
      setButtonTitle("edit app")
      let param = location.pathname.split('/')[3]
      userDetail('applications/detail', { app_id: param })
        .then(res => {
          console.log(res)
          setData(res.data.data)
          createMenuList(res.data.data.menu_list)
          setBusy(false)
        })
    } else {
      setButtonTitle("add application")
    }
  }, []); // Or [] if effect doesn't need props or state


  const createMenuList = (obj) => {
    let all_menu = obj
    let temp_menu_list = [];
    all_menu.map((item,index) => {
      temp_menu_list.push({
        id: item.menu_id,
        index: index +1,
        menu_name : item.menu_name,
        menu_type : item.menu_type,
    })
    })
    setMenuList(temp_menu_list);
  }

  const handleBackButton = () => window.location.href = "/application/list";;



  const handleSaveButton = () => {
    console.log(data.app_name)
    if (data.app_name === "" || null) {
      alert("คุณยังไม่ระบุ ชื่อ \n กรุณาเพิ่ม ชื่อ ")
    }
     else {
      if (mode === "add") {
        let object = {
          "app_name": data.app_name,
          "menu_list" : menu_list
        }
        console.log(object);
        callService('applications/create', object)
          .then(res => {
            alert("เพิ่มแอพพลิเคชั่นสำเร็จ")
            window.location.href = "/application/list";
          })
          .catch(error => {
            console.log(error.response);
            if (error.response) {
              if (error.response.status === 401) {
                window.location.href = "/auth/login";
              }
            }
          })
      } else {
        let org_id = location.pathname.split('/')[3]
        let object = {
          "app_id": org_id,
          "app_name": data.app_name,
          "menu_list" : menu_list[0]
        }
        console.log("ma");
        console.log(menu_list[0]);
        callService('applications/update', object)
          .then(res => {
            alert("แก้ไขผู้ใช้งานสำเร็จ")
            window.location.href = "/application/list";
          })
          .catch(error => {
            console.log(error.response);
            if (error.response) {
              if (error.response.status === 401) {
                window.location.href = "/auth/login";
              }
            }
          })
      }
    }
  }

  if (isBusy) {
    return <div>Loading...</div>;
  } else {
    return (
      <Card style={{ margin: '1.5rem 15%' }}>
        <CardContent>
          <Grid container spacing={2}>
           
            {/* <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-passsword"
                label="Password"
                variant="outlined"
                // type="password"
                value={data.password}
                onChange={event => setData({ ...data, password: event.target.value })}
                style={{ display: 'none' }}
              />
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-name"
                label="ชื่อ"
                variant="outlined"
                value={data.app_name}
                onChange={event => setData({ ...data, app_name: event.target.value })}

              />
            </Grid>
          </Grid>
        </CardContent>

        <CardActions>
          <Grid
            container
            spacing={2}
            direction="row"
            // justifyContent="flex-end"
            alignItems="center"
          >
             <Grid item xs>
              {/* <p style={{marginLeft:10}}>Last Edit: {data.updated_date.split('T')[0]}</p> */}
              <p style={{marginLeft:10}}>Last Edit: {data.updated_date}</p>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="success"
                onClick={handleSaveButton}
              >
                {button_title}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="error"
                onClick={handleBackButton}
              >
                back
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    )
  }
}
export default UserForm;