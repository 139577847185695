export const isLogin = () => {
  if (localStorage.getItem('ACCESS_TOKEN')) {
      return true;
  }
  return false;
  // return true;
}

export const logout = () => {
  localStorage.removeItem('ACCESS_TOKEN');
}