function setAccessToken(token) {
  localStorage.setItem('ACCESS_TOKEN', token);
}

function getAccessToken() {
  return localStorage.getItem('ACCESS_TOKEN');
}

function setRefreshToken(token) {
  localStorage.setItem('REFRESH_TOKEN', token);
}

function getRefreshToken() {
  return localStorage.getItem('REFRESH_TOKEN');
}

function setMenu(menu) {
  localStorage.setItem('MENU', JSON.stringify(menu));
}

function getMenu() {
  return localStorage.getItem('MENU');
}

function removeToken() {
  localStorage.removeItem('ACCESS_TOKEN');
  localStorage.removeItem('MENU');
}

export default {
  setAccessToken,
  getAccessToken,
  removeToken,
  setMenu,
  getMenu,
  setRefreshToken,
  getRefreshToken
};
