import { Button } from "@mui/material";
import react,{ useState,useEffect } from "react";
import { callService, uploadFile } from "../../services/Service";
import axios from "axios";

export default function UploadFile(){
    useEffect(() => {

    },[])

    const [select_file,setSelectFile] = useState(null);

    const handleFileChange = (event) => {
        setSelectFile(event.target.files[0])
        
    }

    const handleFileUpload = () => {
        if (select_file) {
            const formData = new FormData();
            formData.append('file', select_file);

            uploadFile('upload', formData)
            .then(response => {
              console.log(response);
              console.log('File uploaded successfully');
            })
            .catch(error => {
              console.error('File upload failed:', error);
            });
          }
    }

    return (
        <div>
            <Button
            variant="contained"
            component="label"
            >
            Upload File
            <input
            type="file"
            hidden
            onChange={(e) => handleFileChange(e)}
            />
            </Button> 
            <Button
             variant="contained"
             component="label"
            onClick={e => handleFileUpload()}
            >กด</Button>
        </div>
    )
}
