import React, { useState, useEffect } from 'react';
import AdminLayout from '../../components/admin/AdminLayout'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';


import {
  callService
} from '../../services/Service'

export default function UserGroupList() {
  const [user_group_list, setUserGroupList] = useState([])
  const [selected_user_group_id, setSelectedUserGroupID] = useState(0)
  const [selected_organizetion_id, setOrganizeID] = useState(0)
  const [selected_status, setSelectedStatus] = useState(false)
  const [alert_header, setAlertHeader] = useState("")
  const [alert_body, setAlertBody] = useState("")
  const [alert_mode, setAlertMode] = useState("")
  const [organize_list, setOrganizeList] = useState([])
  const [selected_organizetion, setSelectedOrgainzation] = useState(0)
  const [open_alert, setOpenAlert] = useState(false);
  const [search, setSearch] = useState("");


  const handleClose = () => {
    setOpenAlert(false);
    setSelectedUserGroupID(0)
    setOrganizeID(0)
    setSelectedStatus(false)
  };

  const columns = [
    { 
      field: 'index', 
      headerName: 'ลำดับ', 
      maxWidth: 50,
      flex: 1,
    },
    { 
      field: 'group_name', 
      headerName: 'User group name', 
      minWidth: 250,
      flex: 1,
    },
    { 
      field: 'org_name', 
      headerName: 'Organization', 
      minWidth: 250,
      flex: 1,
    },
    // {
    //   field: "status",
    //   headerName: "Activated",
    //   sortable: false,
    //   width: 150,
    //   disableClickEventBubbling: true,
    //   renderCell: (cellValues) => {
    //     return <FormGroup>
    //       <FormControlLabel
    //         control={
    //           <Switch
    //             size="small"
    //             checked={cellValues.row.status}
    //             onClick={(event) => {
    //               handleStatusSwitch(event, cellValues);
    //             }}
    //           />
    //         }
    //         label=""
    //       />
    //     </FormGroup>
    //   }
    // },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      maxWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return <IconButton
          color="secondary"
          aria-label="add an alarm"
          onClick={(event) => {
            handleEditClick(event, cellValues);
          }} >
          <EditIcon />
        </IconButton>
      }
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      maxWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return <IconButton
          color="secondary"
          aria-label="add an alarm"
          onClick={(event) => {
            handleDeleteClick(event, cellValues);
          }} >
          <DeleteIcon />
        </IconButton>
      }
    }
  ];

  const handleStatusSwitch = (event, cellValues) => {
    let user_id = cellValues.row.id
    let user_name = cellValues.row.name
    let status = cellValues.row.status
    let org_id = cellValues.row.org_id
    let header = " User"
    let body = "คุณต้องการ "
    header = status ? 'ปิดการใช้งาน' + header : 'เปิดการใช้งาน' + header
    body = status ? body + ' ปิดการใช้งาน ' : body + ' เปิดการใช้งาน '
    body += "ผู้ใช้งาน " + user_name
    setAlertHeader(header)
    setAlertBody(body)
    setSelectedUserGroupID(user_id)
    setOrganizeID(org_id)
    setSelectedStatus(status)
    setAlertMode("status")
    setOpenAlert(true)
  }

  const handleEditClick = (event, cellValues) => {
    event.preventDefault();
    window.location.href = "/usergroup/edit/" + cellValues.row.org_id + "/" + cellValues.row.id
  }

  const handleDeleteClick = (event, cellValues) => {
    event.preventDefault();
    let user_id = cellValues.row.id
    let group_name = cellValues.row.group_name
    let org_id = cellValues.row.org_id
    let header = "ลบข้อมูล User Group"
    let body = "คุณต้องการลบช้อมูล "
    body += "กลุ่มผู้ใช้งาน " + group_name
    setAlertHeader(header)
    setAlertBody(body)
    setSelectedUserGroupID(user_id)
    setOrganizeID(org_id)
    setAlertMode("delete")
    setOpenAlert(true)
  }

  const createUserList = () => {
    callService('organizations/group/get', { 'org_id': 0 })
      .then(res => {
        let all_user = res.data.data
        console.log(all_user);
        let temp_user_list = []
        all_user.map((item, index) => {
          temp_user_list.push({
            id: item.group_id,
            index: index + 1,
            group_name: item.group_name,
            org_name: item.org_name,
            org_id: item.org_id
          })
        })
        setUserGroupList(temp_user_list)
      })
      .catch(error => {
        console.log(error.response);
        if (error.response.status === 401) {
          window.location.href = "/auth/login";
        }
      })
  }

  const handleAddClick = (event) => {
    event.preventDefault();
    window.location.href = "/usergroup/add";
  }

  const handleSearchClick = (event) => {
    event.preventDefault();
    searchUserList();
  }

  const searchUserList = () => {
    let obj = {
      org_id: selected_organizetion,
      search: search
    }
    callService('organizations/group/get', obj)
      .then(res => {
        let all_user = res.data.data;
        let temp_user_list = []
        all_user.map((item, index) => {
          temp_user_list.push({
            id: item.group_id,
            index: index + 1,
            group_name: item.group_name,
            org_id: item.org_id
          })
        })
        setUserGroupList(temp_user_list)
      })
      .catch(error => {
        if (error.response.status === 401) {
          window.location.href = "/auth/login";
        }
      })
  }

  const handleAlertButton = () => {
    if (alert_mode === "delete") {
      let obj = {
        org_id: selected_organizetion_id,
        group_id: selected_user_group_id
      }
      callService("organizations/group/delete", obj)
        .then(res => {
          handleClose()
          setUserGroupList([])
          createUserList()
        }).catch(error => {
          console.log(error.response);
          if (error.response) {
            if (error.response.status === 401) {
              window.location.href = "/auth/login";
            }
          }
        })
    } else {
      let obj = {
        user_id: selected_user_group_id,
        active: !selected_status
      }
      // setActivate(obj).then(res => {
      //   handleClose()
      //   setUserList([])
      //   createUserList()
      // }).catch(error=>{
      //   console.log(error.response.data);
      //   // if (error.response) {
      //   //   if (error.response.status === 401){
      //   //     setIsSuccess(false)
      //   //     setData("ไม่พบชื่อผู้ใช้งานในระบบ")
      //   //   }
      //   // }
      // })
    }
  }

  useEffect(async () => {
    callService('organizations/get').then(res => {
      let organize_lists = []
      let data = res.data.data
      data.map((item) => {
        organize_lists.push({
          id: item.org_id,
          name: item.org_name
        })
      })
      setOrganizeList(organize_lists);
    }).catch(error => {
      console.log(error.response.data.response);
    })
    createUserList()
  }, []);

  return (
    <div style={{ margin: '2rem' }}>
      <AdminLayout />
      {/* <Grid container spacing={2} style={{ marginTop: '4rem', marginBottom: '2rem' }}>
        <Grid item xs={3}>
          <Select
            labelid="demo-simple-select-label"
            id="demo-simple-select"
            value={selected_organizetion}
            label="องค์กร"
            fullWidth
            onChange={event => setSelectedOrgainzation(event.target.value)}
          >
            <MenuItem key={0} value={0}>กรุณาเลือกองค์กร</MenuItem>
            {organize_list.map((item, index) => {
              return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
            })}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="ค้นหา"
            variant="outlined"
            value={search}
            onChange={event => setSearch(event.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            sx={{ width: 200, height: 50 }}
            onClick={handleSearchClick}
          >
            ค้นหา
          </Button>
        </Grid>
      </Grid> */}
      <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
        <Grid item xs>
          <Typography variant='h4' style={{ fontWeight: 'bold' }}>User Group Management</Typography>
        </Grid>
        <Grid item >
          <Button
            variant="contained"
            onClick={handleAddClick}
          >
            ADD USER GROUP
          </Button>
        </Grid>
        <Grid item xs={12} >
          <div style={{ height: 600, width: '100%', marginTop: '2rem' }}>
            <DataGrid
              style={{ backgroundColor: "#FFFFFF" }}
              rows={user_group_list}
              columns={columns}
              rowsPerPageOptions={[]}
            />
          </div>
        </Grid>
      </Grid>
      <Dialog
        open={open_alert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {alert_header}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alert_body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertButton} variant="contained" color="success">
            ตกลง
          </Button>
          <Button onClick={handleClose} variant="contained" color="error">
            ยกเลิก
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}