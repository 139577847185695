import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material'



import {
  useLocation
} from 'react-router-dom';

import {
  callService
} from '../services/Service'




const UserGroupForm = ({ mode }) => {
  const location = useLocation();
  const [button_title, setButtonTitle] = useState("")
  const [group_name, setGroupName] = useState("")
  const [group_desc, setGroupDesc] = useState("")
  const [homepage_uri, setHomePageURI] = useState("")
  const [organize_list, setOrganizeList] = useState([])
  const [user_list, setUserList] = useState([])
  const [all_user_list, setAllUserList] = useState([])
  const [selected_user_list, setSelectedUserList] = useState(0)
  const [selected_organizetion, setSelectedOrgainzation] = useState(0)
  const [openDialog, setOpenDialog] = useState(false)
  const [alert_header, setAlertHeader] = useState("")
  const [alert_body, setAlertBody] = useState("")
  const [alert_mode, setAlertMode] = useState("")
  const [select_user_id, setSelectUserID] = useState(0)


  const columns = [
    { 
      field: 'index', 
      headerName: 'ลำดับ', 
      maxWidth: 50,
      flex: 1,
    },
    { 
      field: 'username', 
      headerName: 'username', 
      minWidth: 350,
      flex: 1,
    },
    { 
      field: 'full_name', 
      headerName: 'ชื่อ-นามสกุล', 
      minWidth: 250,
      flex: 1, 
    },
    { 
      field: 'email', 
      headerName: 'อีเมล', 
      minWidth: 250, 
      flex: 1,
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      maxWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return <IconButton
          color="secondary"
          aria-label="add an alarm"
          onClick={(event) => {
            handleDeleteClick(event, cellValues);
          }} >
          <DeleteIcon />
        </IconButton>
      }
    }
  ];

  const handleDeleteClick = (event, cellValues) => {
    event.preventDefault();
    let user_id = cellValues.row.id
    let username = cellValues.row.username


    let header = "ยกเลิกผู้ใช้";
    let body = "ยกเลิกผู้ใช้ ";
    body += "ผู้ใช้ " + username;
    setAlertHeader(header);
    setAlertBody(body);
    setSelectUserID(user_id)
    setAlertMode("Delete");
    setOpenDialog(true);
    // let object = {
    //   "user_id" : user_id,
    //   "org_id": org_id
    // }
    // callService('organizations/users/remove',object)
    // .then(res => {
    //   window.location.href = "/usergroup/edit/" + org_id + "/" + group_id
    // }).catch(error => {
    //   console.log(error.response.data.response);
    // })
  }

  const handleBackButton = () => window.location.href = "/usergroup/list";

  const handleSaveButton = () => {
    if (group_name === "" || null) {
      alert("คุณยังไม่ระบุ ชื่อ \n กรุณาเพิ่ม ชื่อ")
    } else if (selected_organizetion === 0 || null) {
      alert("คุณยังไม่ได้เลือกหน่วยงาน \n กรุณาเลือก หน่วยงาน")
    }
    else if (homepage_uri === "" || null) {
      alert("คุณยังไม่ระบุ Uri \n กรุณาเพิ่ม Uri")
    }
    else {
      if (mode === "add") {
        let object = {
          "group_name": group_name,
          "group_desc": group_desc,
          "homepage_uri": homepage_uri,
          "org_id": selected_organizetion
        }
        console.log(object);
        callService('organizations/group/create', object)
          .then(res => {
            alert("เพิ่มกลุ่มผู้ใช้งานสำเร็จ")
            window.location.href = "/usergroup/list";
          })
          .catch(error => {
            console.log(error.response);
            if (error.response) {
              if (error.response.status === 401) {
                window.location.href = "/auth/login";
              }
            }
          })
      } else {
        let group_id = location.pathname.split('/')[4]
        let object = {
          "org_id": selected_organizetion,
          "group_id": group_id,
          "group_name": group_name,
          "group_desc": group_desc,
          "homepage_uri": homepage_uri,
        }
        console.log(object);
        callService('organizations/group/update', object)
          .then(res => {
            alert("แก้ไขกลุ่มผู้ใช้สำเร็จ")
            window.location.href = "/usergroup/list";
          })
          .catch(error => {
            console.log(error.response);
            if (error.response) {
              if (error.response.status === 401) {
                window.location.href = "/auth/login";
              }
            }
          })
      }
    }
  }


  const handleAddButton = () => {
    setSelectedUserList(0);
    let header = "เพิ่มผู้ใช้"
    setAlertHeader(header);
    setAlertBody("");
    setAlertMode("Add");
    console.log(alert_mode);
    setOpenDialog(true);
  }

  const handleAcceptClick = () => {
    let org_id = location.pathname.split('/')[3]
    let group_id = location.pathname.split('/')[4]

    if (alert_mode === "Add") {
      let dup = user_list.find(function (item) { return item.id === selected_user_list });
      console.log(user_list);
      console.log(dup);
      if (dup !== null && dup !== undefined) {
        alert("ไม่สามารถเพิ่มผู้ใช้นี้ได้ \nเนื่องมีผู้ใช้นี้แล้ว")
      } else {
        let object = {
          org_id: org_id,
          group_id: group_id,
          user_id: selected_user_list
        }
        console.log(object);
        callService('organizations/users/add', object)
          .then(res => {
            window.location.href = "/usergroup/edit/" + org_id + "/" + group_id;
          }).catch(error => {
            console.log(error.response.data.response);
          })
      }
    }
    else {
      let user_id = select_user_id;
      let object = {
        "org_id": org_id,
        "group_id": group_id,
        "user_id": user_id
      }
      callService('organizations/users/remove', object)
        .then(res => {
          window.location.href = "/usergroup/edit/" + org_id + "/" + group_id;
        }).catch(error => {
          console.log(error.response.data.response);
        })
    }
  }


  const handleCloseClick = () => {
    setOpenDialog(false);
  }

  useEffect(async () => {
    callService('organizations/get').then(res => {
      let organize_lists = []
      let data = res.data.data
      data.map((item) => {
        organize_lists.push({
          "id": item.org_id,
          "name": item.org_name
        })
      })
      setOrganizeList(organize_lists);
    }).catch(error => {
      console.log(error.response.data.response);
    })
    if (mode === "edit") {
      callService('users').then(res => {
        let temp_user_list = []
        let data = res.data.data
        data.map((item) => {
          temp_user_list.push({
            "id": item.user_id,
            "name": item.username
          })
        })
        setAllUserList(temp_user_list);
      }).catch(error => {
        console.log(error.response.data.response);
      })
      setButtonTitle("แก้ไขกลุ่มผู้ใช้งาน")
      let group_id = location.pathname.split('/')[4]
      let org_id = location.pathname.split('/')[3]
      let object = {
        org_id: org_id,
        group_id: group_id
      }
      callService('organizations/group/get_detail', object).then(
        res => {
          let group_details = res.data.data
          console.log(group_details);
          setSelectedOrgainzation(group_details.org_id)
          setGroupName(group_details.group_name)
          setHomePageURI(group_details.homepage_uri)
          setGroupDesc(group_details.group_desc)

          let temp_user_list = [];
          group_details.user_list.map((item, index) => {
            temp_user_list.push({
              index: index + 1,
              id: item.user_id,
              username: item.username,
              "last_name": item.last_name,
              "full_name": item.full_name,
              "email": item.email
            })
          })
          setUserList(temp_user_list);
        }
      )

    } else {
      setButtonTitle("เพิ่มกลุ่มผู้ใช้งาน")
    }
  }, []);

  return (
    <Card style={{ marginTop: '1.5rem', marginRight: '10%', marginLeft: '10%' }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Select
              labelid="demo-simple-select-label"
              id="demo-simple-select"
              value={selected_organizetion}
              label="หน่วยงาน"
              disabled={mode === "edit" ? true : false}
              fullWidth
              onChange={event => setSelectedOrgainzation(event.target.value)}
            >
              <MenuItem key={0} value={0}>กรุณาเลือกองค์กร</MenuItem>
              {organize_list.map((item, index) => {
                return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
              })}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="ชื่อ User Group"
              variant="outlined"
              value={group_name}
              onChange={event => setGroupName(event.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="URI หน้าแรก"
              variant="outlined"
              value={homepage_uri}
              onChange={event => setHomePageURI(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="รายละเอียด User Group"
              variant="outlined"
              multiline
              rows={5}
              maxRows={5}
              value={group_desc}
              onChange={event => setGroupDesc(event.target.value)}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item ></Grid>
          <Grid item>
            <Button
              variant="contained"
              color="success"
              onClick={handleSaveButton}
            >
              {button_title}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="error"
              onClick={handleBackButton}
            >
              ย้อนกลับ
            </Button>
          </Grid>
        </Grid>
      </CardActions>

      <CardActions >
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="success"
            sx={{ display: mode === "add" ? 'none' : 'inline' }}
            onClick={handleAddButton}
          >
            ADD USER
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Dialog
            open={openDialog}
            onClose={handleCloseClick}
            fullWidth
            aria-labelledby="AddUser-Dialog-title"
            aria-describedby="AddUser-Dialog-Content"
          >
            <DialogTitle id="AddUser-Dialog-title">{alert_header}</DialogTitle>
            <DialogContent id="AddUser-Dialog-Content">
              {alert_mode === "Delete" && (<DialogContentText>{alert_body}</DialogContentText>)}
              {alert_mode === "Add" && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Select
                      labelid="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selected_user_list}
                      label="หน่วยงาน"
                      fullWidth
                      onChange={event => setSelectedUserList(event.target.value)}
                    >
                      <MenuItem key={0} value={0}><em>กรุณาเลือกผู้ใช้งาน</em></MenuItem>
                      {all_user_list.map((item, index) => {
                        return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
                      })}
                    </Select>
                  </Grid>
                </Grid>)}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleAcceptClick}
                variant="contained"
                color="success">
                ตกลง
              </Button>

              <Button
                variant="contained"
                hidden={mode === "add"}
                onClick={handleCloseClick}
                color="error">
                ยกเลิก
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </CardActions>
      <CardActions>
        {mode === "edit" && (
          <Grid item xs={12}>
            <div style={{ height: 600, width: '100%', marginTop: '2rem' }}>
              <DataGrid
                style={{ backgroundColor: "#FFFFFF" }}
                rows={user_list}
                columns={columns}
                rowsPerPageOptions={[]}
              />
            </div>
          </Grid>
        )}
      </CardActions>
    </Card>
  )
}
export default UserGroupForm;