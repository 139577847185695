import React, { useState, useEffect } from 'react';
import AdminLayout from '../../components/admin/AdminLayout'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid } from '@mui/x-data-grid';

import {
  getMenuList
} from '../../services/admin/Menu'
import { callService } from '../../services/Service';

export default function OrgList() {
  const [org_list, setOrgList] = useState([])
  const [selected_menu_id, setSelectedMenuID] = useState(0)
  const [selected_app_id,setSelectedAppID] = useState(0)
  const [alert_header, setAlertHeader] = useState("")
  const [alert_body, setAlertBody] = useState("")
  const [alert_mode, setAlertMode] = useState("")
  const [open_alert, setOpenAlert] = useState(false);

  const handleClose = () => {
    setOpenAlert(false);
    setSelectedMenuID(0)
    setSelectedAppID(0)
  };

  const columns = [
    { 
      field: 'index', 
      headerName: 'ลำดับ', 
      maxWidth: 50,
      flex:1
    },
    { 
      field: 'menu_name', 
      headerName: 'ชื่อเมนู', 
      minWidth: 250,
      flex:1
    },
    { 
      field: 'menu_type', 
      headerName: 'ประเภท', 
      minWidth: 250,
      flex:1
    },
    { 
      field: 'app_name', 
      headerName: 'แอพพลิเคชั่น', 
      minWidth: 250,
      flex:1
    },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      maxWidth: 100,
      flex:1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return <IconButton
          color="secondary"
          aria-label="add an alarm"
          onClick={(event) => {
            handleEditClick(event, cellValues);
          }} >
          <EditIcon />
        </IconButton>
      }
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      maxWidth: 100,
      flex:1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return <IconButton
          color="secondary"
          aria-label="add an alarm"
          onClick={(event) => {
            handleDeleteClick(event, cellValues);
          }} >
          <DeleteIcon />
        </IconButton>
      }
    }
  ];

  const handleEditClick = (event, cellValues) => {
    event.preventDefault();
    window.location.href = "/menu/edit/"+ cellValues.row.app_id +"/" + cellValues.row.id
  }

  const handleDeleteClick = (event, cellValues) => {
    event.preventDefault();
    let menu_id = cellValues.row.id
    let app_id = cellValues.row.app_id
    let menu_name = cellValues.row.menu_name
    let menu_type = cellValues.row.menu_type
    let header = "ลบข้อมูลเมนู"
    let body = "คุณต้องการลบข้อมูล "
    body += "เมนู " + menu_name
    if(menu_type === "main") body += " ซึ่งเป็นเมนูหลัก"
    setAlertHeader(header)
    setAlertBody(body)
    setSelectedMenuID(menu_id)
    setSelectedAppID(app_id)
    setAlertMode("delete")
    setOpenAlert(true)
  }

  const createMenuList = () => {
    let Object = {
      "app_id" : 0
    }
    getMenuList(Object).then(res => {
      let all_menu = res.data.data
      console.log(all_menu);
      let temp_menu_list = []
      all_menu.map((item, index) => {
        temp_menu_list.push({
          id: item.menu_id,
          index: index + 1,
          menu_name: item.menu_name,
          menu_type: item.menu_type,
          app_id : item.app_id,
          app_name : item.app_name
        })
      })
      setOrgList(temp_menu_list)
    }).catch(error => {
      console.log(error.response);
      if (error.response.status === 401) {
        window.location.href = "/auth/login";
      }
    })
  }

  const handleAddClick = (event) => {
    event.preventDefault();
    window.location.href = "/menu/add";
  }

  const handleAlertButton = () => {
    let obj = {
      menu_id: selected_menu_id,
      app_id: selected_app_id
    }
    console.log(obj);
    callService("applications/menu/delete", obj).then(res => {
      handleClose()
      setOrgList([])
      createMenuList()
    }).catch(error => {
      console.log(error.response);
      if (error.response) {
        if (error.response.status === 401) {
          window.location.href = "/auth/login";
        }
      }
    })
  }

  useEffect(() => {
    createMenuList()
  }, [])

  return (
    <div style={{ margin: '2rem' }}>
      <AdminLayout />
      <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
        <Grid item xs>
          <Typography variant='h4' style={{ fontWeight: 'bold' }}>Menu Management</Typography>
        </Grid>
        <Grid item >
          <Button
            variant="contained"
            onClick={handleAddClick}
          >
            ADD MENU
          </Button>
        </Grid>
        <Grid item xs={12} >
          <div style={{ height: 600, width: '100%', marginTop: '2rem' }}>
            <DataGrid
              style={{ backgroundColor: "#FFFFFF" }}
              rows={org_list}
              columns={columns}
              rowsPerPageOptions={[]}
            />
          </div>
        </Grid>
      </Grid>
      <Dialog
        open={open_alert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {alert_header}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alert_body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertButton} variant="contained" color="success">
            ตกลง
          </Button>
          <Button onClick={handleClose} variant="contained" color="error">
            ยกเลิก
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}