import axios from 'axios';
import config from '../config.json';
import LocalStorage from '../utils/LocalStorage';

export const callService = async (uri, obj) => {
  let token = LocalStorage.getAccessToken();
  return axios.post(
    config.BASE_API + uri,
    obj,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const uploadFile = async (uri, obj) => {
  let token = LocalStorage.getAccessToken();
  console.log(obj)
  return axios.post(
    config.BASE_API + uri, obj,
    {
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwib3JnX2lkIjowLCJ1c2VyX2lkIjoxLCJ1c2VybmFtZSI6ImFkbWluIiwiZmlyc3RfbmFtZSI6ImFkbWluIiwibGFzdF9uYW1lIjoidGVzdCIsImlhdCI6MTY3OTU5MjQ4MiwiZXhwIjoxNjc5Njc4ODgyfQ.LSk6a5cIa67crEdwtJeEHx1fc4_D1vsG7z6Rb8d-ZSc'
      }
    }
  )
}

export const userDetail = async (uri, obj) => {
  let token = LocalStorage.getAccessToken();
  return axios.post(
    config.BASE_API + uri, obj,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}