import React, { useState, useEffect } from 'react';
import AdminLayout from '../../components/admin/AdminLayout'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid } from '@mui/x-data-grid';

import {
  callService
} from '../../services/Service'
import { setActivate } from '../../services/admin/Application';

export default function UserList() {
  const [app_list, setAppList] = useState([])
  const [selected_app_id, setSelectedAppID] = useState(0)
  const [selected_status, setSelectedStatus] = useState(false)
  const [alert_header, setAlertHeader] = useState("")
  const [alert_body, setAlertBody] = useState("")
  const [alert_mode, setAlertMode] = useState("")
  const [open_alert, setOpenAlert] = useState(false);

  const handleClose = () => {
    setOpenAlert(false);
    setSelectedAppID(0)
    setSelectedStatus(false)
  };

  const columns = [
    { 
      field: 'index', 
      headerName: 'ลำดับ', 
      maxWidth: 100,
      flex: 1,
    },
    { 
      field: 'id', 
      headerName: 'Application Id', minWidth: 250,
      flex: 1,
    },
    { 
      field: 'app_name', 
      headerName: 'Application Name', minWidth: 250,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      maxWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return <FormGroup>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={cellValues.row.status == 'A'}
                onClick={(event) => {
                  handleStatusSwitch(event, cellValues);
                }}
              />
            }
            label=""
          />
        </FormGroup>
      }
    },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      maxWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return <IconButton
          color="secondary"
          aria-label="add an alarm"
          onClick={(event) => {
            handleEditClick(event, cellValues);
          }} >
          <EditIcon />
        </IconButton>
      }
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return <IconButton
          color="secondary"
          aria-label="add an alarm"
          onClick={(event) => {
            handleDeleteClick(event, cellValues);
          }} >
          <DeleteIcon />
        </IconButton>
      }
    }
  ];

  const handleStatusSwitch = (event, cellValues) => {
    let app_id = cellValues.row.id
    let app_name = cellValues.row.app_name
    let status = cellValues.row.status === 'A'
    let header = " User"
    let body = "คุณต้องการ "
    header = status ? 'ปิดการใช้งาน' + header : 'เปิดการใช้งาน' + header
    body = status ? body + ' ปิดการใช้งาน ' : body + ' เปิดการใช้งาน '
    body += "แอพพลิเคชั่น " + app_name
    setAlertHeader(header)
    setAlertBody(body)
    setSelectedAppID(app_id)
    setSelectedStatus(status)
    setAlertMode("status")
    setOpenAlert(true)
  }
 

  const handleEditClick = (event, cellValues) => {
    event.preventDefault();
    window.location.href = "/application/edit/" + cellValues.row.id
  }

  const handleDeleteClick = (event, cellValues) => {
    event.preventDefault();
    let app_id = cellValues.row.id
    let app_name = cellValues.row.app_name
    let header = "ลบข้อมูล User"
    let body = "คุณต้องการลบช้อมูล "
    body += "ผู้ใช้งาน " + app_name
    setAlertHeader(header)
    setAlertBody(body)
    setSelectedAppID(app_id)
    setAlertMode("delete")
    setOpenAlert(true)
  }

  const createAppList = () => {
    callService('applications')
      .then(res => {
        let all_app = res.data.data
        console.log('all_app',all_app);
        
        let temp_app_list = []
        all_app.map((item, index) => {
          temp_app_list.push({
            id: item.app_id,
            index: index + 1,
            app_name: item.app_name,
            status : item.app_status
          })
        })
        setAppList(temp_app_list)
      })
      .catch(error => {
        console.log(error.response);
        if (error.response.status === 401) {
          window.location.href = "/auth/login";
        }
      })
  }

  const handleAddClick = (event) => {
    event.preventDefault();
    window.location.href = "/application/add";
  }

  const handleAlertButton = () => {
    if (alert_mode === "delete") {
      let obj = {
        app_id: selected_app_id,
      }
      console.log(obj);
      callService("applications/delete", obj)
        .then(res => {
          handleClose()
          setAppList([])
          createAppList()
        }).catch(error => {
          console.log(error.response);
          if (error.response) {
            if (error.response.status === 401) {
              window.location.href = "/auth/login";
            }
          }
        })
    } else {
      let obj = {
        app_id: selected_app_id,
        status: !selected_status == true ? "A" : "I"
      }
      console.log(obj);
      callService('applications/set_status',obj).then(res => {
        handleClose()
        setAppList([])
        createAppList()
      }).catch(error=>{
        if(error.response){
          if(error.response.status === 401){
            window.location.href = "/auth/login";
          }
        }
      })
    }
  }
  
  useEffect(() => {
    async function fetchData() {
      // You can await here
      // const response = await MyAPI.getData(someId);
      createAppList()
      // ...
    }
    fetchData();
  }, []); // Or [] if effect doesn't need props or state

  return (
    <div style={{ margin: '2rem' }}>
      <AdminLayout />
      <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
        <Grid item xs>
          <Typography variant='h4' style={{ fontWeight: 'bold' }}>Application Management</Typography>
        </Grid>
        <Grid item >
          <Button
            variant="contained"
            onClick={handleAddClick}
          >
            ADD APPLICATION
          </Button>
        </Grid>
        <Grid item xs={12} >
          <div style={{ height: 600, width: '100%', marginTop: '2rem' }}>
            <DataGrid
              style={{ backgroundColor: "#FFFFFF" }}
              rows={app_list}
              columns={columns}
              rowsPerPageOptions={[]}
            />
          </div>
        </Grid>
      </Grid>
      
      <Dialog
        open={open_alert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {alert_header}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alert_body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertButton} variant="contained" color="success">
            ตกลง
          </Button>
          <Button onClick={handleClose} variant="contained" color="error">
            ยกเลิก
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}