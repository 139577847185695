/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import { 
  Card,
  Grid,
  CardActions,
  CardContent,
  TextField,
  Button,
  Select,
  MenuItem,
  Switch,
  FormGroup,
  FormControlLabel
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid } from '@mui/x-data-grid';
import {
  useLocation
} from 'react-router-dom';

import {
  addMenu,
  updateMenu,
  menuDetail
} from '../services/admin/Menu'

import {
  callService,
} from '../services/Service'


const MenuForm = ({ mode }) => {
  const location = useLocation();
  const [button_title, setButtonTitle] = useState("")
  const [selected_application,setSelectedApplication] = useState(0)
  const [selected_main_menu,setSelectedMainMenu] = useState(0)
  const [main_menu_list,setMainMenuList] = useState([])
  const [selected_sub_menu,setSelectedSubMenu] = useState(0)
  const [sub_menu_list,setSubMenuList] = useState([])
  const [application_list,setApplicationList]= useState([])
  const [group_list,setGroupList] = useState([])
  const [alert_header, setAlertHeader] = useState("")
  const [alert_body, setAlertBody] = useState("")
  const [alert_mode, setAlertMode] = useState("")
  const [open_alert, setOpenAlert] = useState(false);
  const [select_group_id,setSelectGroupID] = useState(0)
  const [select_org_id,setSelectOrgID] = useState(0)
  const [select_status,setSelectStatus] = useState()

  const [data, setData] = useState({
    org_name: "",
    org_code: "",
  })

  const [isBusy, setBusy] = useState(mode === "add" ? false : true)



  const handleBackButton = () => window.location.href = "/permis/list";;

  useEffect(() => {
    createApplicationDropdown()
    createGroupList()
    if (mode === "edit") {
      
      setButtonTitle("แก้ไขเมนู")
      let app_id = location.pathname.split('/')[3]
      let permis_id = location.pathname.split('/')[4]
      let object = {
        "app_id" : app_id,
        "permis_id" : permis_id
      }
      callService('applications/permis/detail',object)
        .then(res => {
          let detail = res.data.data[0]
          setData(res.data.data[0])
          setSelectedApplication(detail.app_id)
          handleSelectedApplication(detail.app_id);
          setSelectedMainMenu(detail.main_menu_id);
          setSelectedSubMenu(detail.sub_menu_id);
          setBusy(false)
        })
    } else {
      setButtonTitle("เพิ่มเมนู")
    }

  }, []);

  

  const columns = [
    { 
      field: 'index', 
      headerName: 'ลำดับ', 
      maxWidth: 50,
      flex: 1,
    },
    { 
      field: 'group_name', 
      headerName: 'ชื่อกลุ่มผู้ใช้',
      minWidth: 350,
      flex: 1,
    },
    { 
      field: 'org_name', 
      headerName: 'ชื่อหน่วยงาน', 
      minWidth: 250,
      flex: 1,},
    {
      field: "permis_value",
      headerName: "Status",
      sortable: false,
      maxWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return <FormGroup>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={cellValues.row.value}
                onClick={(event) => {
                  handleStatusSwitch(event, cellValues);
                }}
              />
            }
            label=""
          />
        </FormGroup>
      }
    }
  ]
  const handleStatusSwitch = (event,cellValues) => {
    let group_id = cellValues.row.id
    let org_id = cellValues.row.org_id
    let group_name = cellValues.row.group_name
    let status = cellValues.row.value
    console.log(status);
    let header = " Group"
    let body = "คุณต้องการ "
    header = status ? 'ปิด Permission' + header : 'เปิด Permission' + header
    body = status ? body + ' ปิด Permission ' : body + ' เปิด Permission '
    body += "กลุ่มผู้ใช้งาน " + group_name
    setAlertHeader(header)
    setAlertBody(body)
    setSelectOrgID(org_id)
    setSelectGroupID(group_id)
    setSelectStatus(status)
    setAlertMode("status")
    setOpenAlert(true)

  }

  const handleAlertButton = () => {
    
    const itemIndex = group_list.findIndex(
      item => item.id === select_group_id && item.org_id === select_org_id
    );
     
    if(itemIndex !== -1){
      const updateGroupList = [...group_list];
      updateGroupList[itemIndex].value = !select_status;
      console.log(updateGroupList[itemIndex].value);
      setGroupList(updateGroupList);
    }
    handleClose();
  }
  
  const handleClose = () => {
    setOpenAlert(false)
  }


  const createGroupList = () => {
    let permis_id = location.pathname.split('/')[4]
    console.log(permis_id);
    let obj = {
      "permis_id" : permis_id
    }
    callService('organizations/group/get_permis',obj)
    .then(res => {
       let temp_group_list = []
       let group_detail = res.data.data
       group_detail.map((item,index) => {
        temp_group_list.push({
          id : item.group_id,
          index : index + 1,
          value : item.permis_value,
          group_name : item.group_name,
          org_name : item.org_name,
          org_id : item.org_id
        })
      })
      setGroupList(temp_group_list);
    }).catch(error => {
      console.log(error.response);
    })
  }

  

  const createApplicationDropdown = () => {
    callService('applications').then(
      res => {
        let app_list = [];
        let data = res.data.data;
        data.map((item) => {
          app_list.push({
            "id" : item.app_id,
            "name" : item.app_name
          })
        })
        setApplicationList(app_list);
        console.log(app_list);
      }).catch(error =>{
        console.log(error.response.data.response);
      })
  }

  const handleSelectedApplication = (value) =>{
    if(mode !== "edit"){
      setSelectedMainMenu(0);
      setSelectedSubMenu(0);
    }
    if(value !== 0){
      let object = {
        "app_id" : value,
        "menu_type" : "main"
      }
      callService('applications/menu/get/type',object)
      .then(res => {
        let main_list = [];
        let data = res.data.data
        data.map((item) => {
          main_list.push({
            "id" : item.menu_id,
            "name" : item.menu_name
          })
        })
        setMainMenuList(main_list);
      }).catch( error => {
        console.log(error.response.data.response);
      })
      object = {
        "app_id" : value,
        "menu_type" : "sub"
      }
      callService('applications/menu/get/type',object)
      .then(res => {
        let sub_menu_list = [];
        let data = res.data.data
        data.map((item) => {
          sub_menu_list.push({
            "id" : item.menu_id,
            "name" : item.menu_name
          })
        })
        setSubMenuList(sub_menu_list);
      }).catch( error => {
        console.log(error.response.data.response);
      })
    }else{
      setMainMenuList([])
      setSubMenuList([])
    }
    return setSelectedApplication(value);
  }

  const handleSaveButton = () => {
    if (data.selected_application === 0) {
      alert("คุณยังไม่ระบุแอพพลิเคชั่น \n กรุณาเลือกแอพพลิเคชั่น")
    } else if (data.menu_name === "" ) {
      alert("คุณยังไม่ระบุชื่อเมนู \n กรุณาเพิ่มชื่อเมนู")
    }
     else {
      if (mode === "add") {
        let object = {
          "app_id": selected_application,
          "permis_name": data.permis_name,
          "permis_action" : data.permis_action,
          "permis_desc" : data.permis_desc,
          "main_menu_id" : selected_main_menu,
          "sub_menu_id" : selected_sub_menu,
        }
       

        callService('applications/permis/create',object)
          .then(res => {
            alert("เพิ่มเมนูสำเร็จ")
            window.location.href = "/permis/list";
          })
          .catch(error => {
            console.log(error.response);
            if (error.response) {
              if (error.response.status === 401) {
                window.location.href = "/auth/login";
              }
            }
          })
      } else {
        //console.log(group_list);
        let app_id = location.pathname.split('/')[3]
        let permis_id = location.pathname.split('/')[4]
        let object = {
          "app_id": app_id,
          "permis_id": permis_id,
          "permis_name":data.permis_name,
          "permis_action":data.permis_action,
          "permis_desc": data.permis_desc,
          "main_menu_id":selected_main_menu,
          "sub_menu_id":selected_sub_menu
        }
        callService('applications/permis/update',object)
          .then(res => { 
                updateGroupList(permis_id);
                //window.location.href = "/permis/list";
          })
          .catch(error => {
            console.log(error.response);
            if (error.response) {
              if (error.response.status === 401) {
                window.location.href = "/auth/login";
              }
            }
          })
      }
    }
  }
  const updateGroupList = (permisId) => {
    const groupUpdateList = group_list.map((groupItem) => {
      return {
        group_id: groupItem.id,
        permis_id: permisId,
        permis_value: groupItem.value,
      };
    });
  
    const object = {
      org_id: 1, // Set the appropriate org_id here
      list: groupUpdateList,
    };
    console.log(object);
    callService("organizations/group/permis", object)
      .then((res) => {
        alert( "แก้ไขเมนูสำเร็จ");
        window.location.href = "/permis/list";
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  

  if (isBusy) {
    return <div>Loading...</div>;
  } else {
    return (
      <Card style={{ marginTop: '1.5rem', marginRight: '10%', marginLeft: '10%' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
            <Select
            labelid="select-app_id-label"
            id="select-app_id"
            value={selected_application}
            label="แอพพลิเคชั่น"
            disabled = {mode==="edit"? true : false}
            fullWidth
            onChange={event => handleSelectedApplication(event.target.value)}
              >
                <MenuItem key={0} value={0}><em>กรุณาเลือกแอพพลิเคชั่น</em></MenuItem>
                {application_list.map((item,index) => {
                  return (<MenuItem key={index+1} value={item.id}>{item.name}</MenuItem>)
                })}
            </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-permis_name"
                label="ชื่อ Permission"
                variant="outlined"
                value={data.permis_name}
                onChange={event => setData({ ...data, 'permis_name': event.target.value })}
              />
            </Grid>
            <Grid item xs={4}>
            <TextField
                fullWidth
                id="outlined-permis_action"
                label="action"
                variant="outlined"
                value={data.permis_action}
                onChange={event => setData({ ...data, 'permis_action': event.target.value })}
              />
            </Grid>
            <Grid item xs={4}>
            <Select
            labelid="select-parent_menu-label"
            id="select-parent_menu"
            value={selected_main_menu}
            label="ประเภทเมนู"
            disabled = {selected_application === 0}
            fullWidth
            onChange={event => setSelectedMainMenu(event.target.value)}
              >
                <MenuItem key={0} value={0}><em>กรุณาเลือกเมนูหลัก</em></MenuItem>
                {main_menu_list.map((item,index) => {
                  return (<MenuItem key={index+1} value={item.id}>{item.name}</MenuItem>)
                })}
            </Select>
              </Grid>
              <Grid item xs={4}>
            <Select
            labelid="select-parent_menu-label"
            id="select-parent_menu"
            value={selected_sub_menu}
            disabled = {selected_main_menu === 0}
            label="ประเภทเมนู"
            fullWidth
            onChange={event => setSelectedSubMenu(event.target.value)}
              >
                <MenuItem key={0} value={0}><em>กรุณาเลือกเมนูรอง</em></MenuItem>
                {sub_menu_list.map((item,index) => {
                  return (<MenuItem key={index+1} value={item.id}>{item.name}</MenuItem>)
                })}
            </Select>
              </Grid>
              <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="รายละเอียด User Group"
              variant="outlined"
              multiline
              rows={5}
              maxRows={5}
              value={data.permis_desc}
              onChange={event => setData({ ...data, 'permis_desc': event.target.value })}
            />
          </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              <Button
                variant="contained"
                color="success"
                onClick={handleSaveButton}
              >
                {button_title}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="error"
                onClick={handleBackButton}
              >
                ย้อนกลับ
              </Button>
            </Grid>
          </Grid>
          </CardActions>
        <CardActions>
            {mode === "edit" && (
        <Grid item xs={12}>
        <div style={{ height: 600, width: '100%', marginTop: '2rem' }}>
            <DataGrid
              style={{ backgroundColor: "#FFFFFF" }}
              rows={group_list}
              columns={columns}
              rowsPerPageOptions={[]}
            />
          </div>
        </Grid>
            )}
      </CardActions> 
      <Dialog
        open={open_alert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {alert_header}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alert_body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertButton} variant="contained" color="success">
            ตกลง
          </Button>
          <Button onClick={handleClose} variant="contained" color="error">
            ยกเลิก
          </Button>
        </DialogActions>
      </Dialog>
      </Card>
    )
}
}
export default MenuForm;