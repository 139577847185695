import React, { useState, useEffect } from 'react';
import AdminLayout from '../../components/admin/AdminLayout'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid } from '@mui/x-data-grid';

import {
  callService
} from '../../services/Service'

export default function UserList() {
  const [user_list, setUserList] = useState([])
  const [selected_user_id, setSelectedUserID] = useState(0)
  const [selected_status, setSelectedStatus] = useState(false)
  const [alert_header, setAlertHeader] = useState("")
  const [alert_body, setAlertBody] = useState("")
  const [alert_mode, setAlertMode] = useState("")
  const [open_alert, setOpenAlert] = useState(false);

  const handleClose = () => {
    setOpenAlert(false);
    setSelectedUserID(0)
    setSelectedStatus(false)
  };

  const columns = [
    { 
      field: 'index', 
      headerName: 'ลำดับ', 
      maxWidth: 100,
      flex: 1,
    },
    { 
      field: 'user_name', 
      headerName: 'User Name', 
      minWidth: 250,
      flex: 1,
    },
    { 
      field: 'name', 
      headerName: 'ชื่อ-นามสกุล', 
      minWidth: 250,
      flex: 1,
    },
    // {
    //   field: "status",
    //   headerName: "Activated",
    //   sortable: false,
    //   width: 150,
    //   disableClickEventBubbling: true,
    //   renderCell: (cellValues) => {
    //     return <FormGroup>
    //       <FormControlLabel
    //         control={
    //           <Switch
    //             size="small"
    //             checked={cellValues.row.status}
    //             onClick={(event) => {
    //               handleStatusSwitch(event, cellValues);
    //             }}
    //           />
    //         }
    //         label=""
    //       />
    //     </FormGroup>
    //   }
    // },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      maxWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return <IconButton
          color="secondary"
          aria-label="add an alarm"
          onClick={(event) => {
            handleEditClick(event, cellValues);
          }} >
          <EditIcon />
        </IconButton>
      }
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      maxWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return <IconButton
          color="secondary"
          aria-label="add an alarm"
          onClick={(event) => {
            handleDeleteClick(event, cellValues);
          }} >
          <DeleteIcon />
        </IconButton>
      }
    }
  ];

  const handleStatusSwitch = (event, cellValues) => {
    let user_id = cellValues.row.id
    let user_name = cellValues.row.name
    let status = cellValues.row.status
    let header = " User"
    let body = "คุณต้องการ "
    header = status ? 'ปิดการใช้งาน' + header : 'เปิดการใช้งาน' + header
    body = status ? body + ' ปิดการใช้งาน ' : body + ' เปิดการใช้งาน '
    body += "ผู้ใช้งาน " + user_name
    setAlertHeader(header)
    setAlertBody(body)
    setSelectedUserID(user_id)
    setSelectedStatus(status)
    setAlertMode("status")
    setOpenAlert(true)
  }

  const handleEditClick = (event, cellValues) => {
    event.preventDefault();
    window.location.href = "/user/edit/" + cellValues.row.id
  }

  const handleDeleteClick = (event, cellValues) => {
    event.preventDefault();
    let user_id = cellValues.row.id
    let user_name = cellValues.row.name
    let header = "ลบข้อมูล User"
    let body = "คุณต้องการลบช้อมูล "
    body += "ผู้ใช้งาน " + user_name
    setAlertHeader(header)
    setAlertBody(body)
    setSelectedUserID(user_id)
    setAlertMode("delete")
    setOpenAlert(true)
  }

  const createUserList = () => {
    callService('users')
      .then(res => {
        let all_user = res.data.data
        console.log('all_user',all_user);
        
        let temp_user_list = []
        all_user.map((item, index) => {
          temp_user_list.push({
            id: item.user_id,
            index: index + 1,
            user_name: item.username,
            name: item.full_name,
          })
        })
        setUserList(temp_user_list)
      })
      .catch(error => {
        console.log(error.response);
        if (error.response.status === 401) {
          window.location.href = "/auth/login";
        }
      })
  }

  const handleAddClick = (event) => {
    event.preventDefault();
    window.location.href = "/user/add";
  }

  const handleAlertButton = () => {
    if (alert_mode === "delete") {
      let obj = {
        user_id: selected_user_id,
      }
      console.log(obj);
      callService("users/delete", obj)
        .then(res => {
          console.log("IEIE");
          handleClose()
          setUserList([])
          createUserList()
        }).catch(error => {
          console.log(error.response);
          if (error.response) {
            if (error.response.status === 401) {
              window.location.href = "/auth/login";
            }
          }
        })
    } else {
      let obj = {
        user_id: selected_user_id,
        active: !selected_status
      }
      // setActivate(obj).then(res => {
      //   handleClose()
      //   setUserList([])
      //   createUserList()
      // }).catch(error=>{
      //   console.log(error.response.data);
      //   // if (error.response) {
      //   //   if (error.response.status === 401){
      //   //     setIsSuccess(false)
      //   //     setData("ไม่พบชื่อผู้ใช้งานในระบบ")
      //   //   }
      //   // }
      // })
    }
  }

  useEffect(() => {
    async function fetchData() {
      // You can await here
      // const response = await MyAPI.getData(someId);
      createUserList()
      // ...
    }
    fetchData();
  }, []); // Or [] if effect doesn't need props or state

  return (
    <div style={{ margin: '2rem' }}>
      <AdminLayout />
      <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
        <Grid item xs>
          <Typography variant='h4' style={{ fontWeight: 'bold' }}>User Management</Typography>
        </Grid>
        <Grid item >
          <Button
            variant="contained"
            onClick={handleAddClick}
          >
            ADD USER
          </Button>
        </Grid>
        <Grid item xs={12} >
          <div style={{ height: 600, width: '100%', marginTop: '2rem' }}>
            <DataGrid
              style={{ backgroundColor: "#FFFFFF" }}
              rows={user_list}
              columns={columns}
              rowsPerPageOptions={[]}
            />
          </div>
        </Grid>
      </Grid>
      
      <Dialog
        open={open_alert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {alert_header}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alert_body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertButton} variant="contained" color="success">
            ตกลง
          </Button>
          <Button onClick={handleClose} variant="contained" color="error">
            ยกเลิก
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}