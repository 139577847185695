import React from 'react';
// import ReactDOM from 'react-dom/client'; 
import './index.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from './pages/auth/login'
import UserList from './pages/user/UserList';
import OrgList from './pages/org/OrgList';
import OrgAdd from './pages/org/OrgAdd';
import OrgEdit from './pages/org/OrgEdit';
import UserAdd from './pages/user/UserAdd';
import UserEdit from './pages/user/UserEdit';
import UserGroupList from './pages/usergroup/UserGroupList';
import UserGroupAdd from './pages/usergroup/UserGroupAdd';
import ApplicationList from './pages/application/AppList';
import ApplicationAdd from './pages/application/AppAdd';
import ApplicationEdit from './pages/application/AppEdit';
import UserGroupEdit from './pages/usergroup/UserGroupEdit';
import MenuAdd from './pages/menu/MnuAdd';
import MenuList  from './pages/menu/MnuList';
import MenuEdit from './pages/menu/MnuEdit';
import PermisList from './pages/Permission/PermisList'
import PermisAdd from './pages/Permission/PermisAdd'
import PermisEdit from './pages/Permission/PermisEdit'
import UploadFile from './pages/file/upload_file'
import { RiceBowlRounded } from '@mui/icons-material';



function App() { 
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Login />} />
        <Route path="/auth/login" element={<Login />} />
        <Route path="/org/list" element={<OrgList />} />
        <Route path="/org/add" element={<OrgAdd />} />
        <Route path="/org/edit/:id" element={<OrgEdit />} />
        <Route path="/user/list" element={<UserList />} />
        <Route path="/user/add" element={<UserAdd />} />
        <Route path="/user/edit/:id" element={<UserEdit />} />
        <Route path="/usergroup/list" element={<UserGroupList />} />
        <Route path="/usergroup/edit/:org_id/:id" element={<UserGroupEdit />} />
        <Route path="/usergroup/add" element={<UserGroupAdd />} />
        <Route path="/application/list" element={<ApplicationList />} />
        <Route path="/application/add" element={<ApplicationAdd />}/>
        <Route path="/application/edit/:id" element={<ApplicationEdit />}/>
        <Route path="/menu/list" element={<MenuList />} />
        <Route path="/menu/add" element={<MenuAdd />} />
        <Route path="/menu/edit/:appid/:menuid" element={<MenuEdit/>} />
        <Route path="/permis/list" element={<PermisList/>}/>
        <Route path="/permis/add" element={<PermisAdd/>} />
        <Route path="/permis/edit/:appid/:permisid" element={<PermisEdit/>} />
        <Route path="/upload" element={<UploadFile/>} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;