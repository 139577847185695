import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {adminLogin} from '../../services/admin/Auth'
import LocalStorage from '../../utils/LocalStorage'
import Alert from '@mui/material/Alert';
import logo from '../../logo192.png' 

const theme = createTheme();

export default function SignIn() {
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [isSuccess, setIsSuccess] = React.useState(true)
  const [data, setData] = React.useState('')

  const handleSubmit = (event) => {
    event.preventDefault();
    let object = {
      "username": username,
      "password": password,
      "org_id" : 0,
    }
    adminLogin(object).then(res=>{
      console.log(res.data);
      LocalStorage.setAccessToken(res.data.data[0]['accessToken'])
      LocalStorage.setRefreshToken(res.data.data[0]['refreshToken'])
      setIsSuccess(true)
      window.location.href = "/org/list";      
    }).catch(error=>{
      console.log(error.response);
      if (error.response) {
        if (error.response.status === 401){
          setIsSuccess(false)
          setData("ไม่พบชื่อผู้ใช้งานในระบบ")
        }
      }
    })
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" style={{ marginTop: '8rem' }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
          }}
        >
           <img fullwidth="true" src={logo} alt='' />

          
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            {
              !isSuccess ? <Alert severity="error">{data}</Alert> : null
            }
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}

            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}